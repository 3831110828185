var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "d-flex flex-column-fluid flex-center"
  }, [_c('div', {
    staticClass: "pb-13 pt-lg-0 pt-5 text-center"
  }, [_c('h2', {
    staticClass: "font-weight-bold font-size-h2 font-size-h1-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("Welcome to Atlasposting")) + " ")]), _c('p', {
    staticClass: "text-muted font-weight-bold mb-9"
  }, [_vm._v(_vm._s(_vm.$t("General conditions and terms of use")))]), _c('div', {
    staticClass: "checkbox-list"
  }, [_c('div', {
    staticClass: "show-content-terms p-4 mt-4"
  }, [_c('GeneralConditions'), _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.toggleGeneral,
      expression: "toggleGeneral"
    }]
  })], 1), _c('div', {
    staticClass: "show-label-terms"
  }, [_c('b-tooltip', {
    attrs: {
      "target": "CheckboxGeneralId",
      "triggers": "hover click",
      "placement": "top",
      "custom-class": "high-z-index"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Scroll down and read the terms to be able to accept it")) + " ")]), _c('label', {
    staticClass: "checkbox font-weight-bold mt-4 m8",
    class: {
      'checkbox-disabled': _vm.generalDisabled
    },
    attrs: {
      "id": "CheckboxGeneralId",
      "title": _vm.$t("Scroll down and read the terms to be able to accept it")
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkGeneral,
      expression: "checkGeneral"
    }],
    attrs: {
      "type": "checkbox",
      "name": "CheckboxGeneral",
      "disabled": _vm.generalDisabled
    },
    domProps: {
      "checked": Array.isArray(_vm.checkGeneral) ? _vm._i(_vm.checkGeneral, null) > -1 : _vm.checkGeneral
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checkGeneral,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.checkGeneral = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checkGeneral = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checkGeneral = $$c;
        }
      }
    }
  }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Accept the general conditions and terms of use of the AtlasPosting platform.")) + " ")])], 1), _c('div', {
    staticClass: "show-content-terms p-4 mt-10"
  }, [_c('TermCondition'), _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: _vm.toggleTerm,
      expression: "toggleTerm"
    }]
  })], 1), _c('div', {
    staticClass: "show-label-terms"
  }, [_c('b-tooltip', {
    attrs: {
      "target": "termCheckboxId",
      "triggers": "hover click",
      "placement": "top",
      "custom-class": "high-z-index"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Scroll down and read the terms to be able to accept it")) + " ")]), _c('label', {
    staticClass: "checkbox font-weight-bold mb-8 mt-4",
    class: {
      'checkbox-disabled': _vm.termDisabled
    },
    attrs: {
      "id": "termCheckboxId"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.checkTerm,
      expression: "checkTerm"
    }],
    attrs: {
      "type": "checkbox",
      "checked": "checked",
      "name": "termCheckbox",
      "disabled": _vm.termDisabled
    },
    domProps: {
      "checked": Array.isArray(_vm.checkTerm) ? _vm._i(_vm.checkTerm, null) > -1 : _vm.checkTerm
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.checkTerm,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.checkTerm = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.checkTerm = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.checkTerm = $$c;
        }
      }
    }
  }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("ACCEPT_TERMS_CHECKBOX_TEXT")) + " ")])], 1)]), _c('button', {
    staticClass: "mt-6 btn btn-primary font-weight-bold px-9 py-4 my-3",
    attrs: {
      "disabled": _vm.isDisabled
    },
    on: {
      "click": _vm.acceptCondition
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Accept")) + " ")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }