<template>
  <div class="card card-custom">
    <div class="card-body">
      <div class="d-flex flex-column-fluid flex-center">
        <!--begin::Check box-->
        <div class="pb-13 pt-lg-0 pt-5 text-center">
          <h2 class="font-weight-bold font-size-h2 font-size-h1-lg">
            {{ $t("Welcome to Atlasposting") }}
          </h2>
          <p class="text-muted font-weight-bold mb-9">{{ $t("General conditions and terms of use") }}</p>
          <div class="checkbox-list">
            <div class="show-content-terms p-4 mt-4">
              <GeneralConditions />
              <div v-observe-visibility="toggleGeneral"></div>
            </div>
            <div class="show-label-terms">
              <b-tooltip target="CheckboxGeneralId" triggers="hover click" placement="top" custom-class="high-z-index">
                {{ $t("Scroll down and read the terms to be able to accept it") }}
              </b-tooltip>
              <label id="CheckboxGeneralId" class="checkbox font-weight-bold  mt-4 m8"
                :class="{ 'checkbox-disabled': generalDisabled }"
                :title='$t("Scroll down and read the terms to be able to accept it")'>
                <input v-model="checkGeneral" type="checkbox" name="CheckboxGeneral" :disabled="generalDisabled">
                <span></span>
                {{ $t("Accept the general conditions and terms of use of the AtlasPosting platform.") }}
              </label>
            </div>

            <div class="show-content-terms p-4 mt-10">
              <TermCondition />
              <div v-observe-visibility="toggleTerm"></div>
            </div>
            <div class="show-label-terms">
              <b-tooltip target="termCheckboxId" triggers="hover click" placement="top" custom-class="high-z-index">
                {{ $t("Scroll down and read the terms to be able to accept it") }}
              </b-tooltip>
              <label id="termCheckboxId" class="checkbox font-weight-bold mb-8 mt-4"
                :class="{ 'checkbox-disabled': termDisabled }">
                <input v-model="checkTerm" type="checkbox" checked="checked" name="termCheckbox" :disabled="termDisabled">
                <span></span>
                {{ $t("ACCEPT_TERMS_CHECKBOX_TEXT") }}
              </label>
            </div>
          </div>

          <button class="mt-6 btn btn-primary font-weight-bold px-9 py-4 my-3" :disabled="isDisabled"
            @click="acceptCondition">
            {{ $t("Accept") }}
          </button>
        </div>
        <!--end::Check box-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
import { backendErrorSwal } from "@/core/helpers/swal";
import { successToast } from '@/core/helpers';
import { mapState, mapActions, mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TermCondition from "@/view/pages/static/TermCondition.vue";
import GeneralConditions from "@/view/pages/static/GeneralConditions.vue";
import CompanyManagedCompaniesService from "@/core/services/company/company-managedcompanies.service";

export default {
  components: {
    TermCondition,
    GeneralConditions
  },
  data() {
    return {
      generalDisabled: true,
      termDisabled: true,
      checkGeneral: false,
      checkTerm: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["getCurrentManagedCompany"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/login-visual.png";
    },
    isDisabled() {
      return !this.checkGeneral || !this.checkTerm
    }
  },
  beforeMount() {
    if (this.user.managedcompany_set.find(managedCompany => managedCompany.company == this.user.selected_company).accepted_condition) {
      const path = this.$route.query.redirect;
      this.$router.push(path || '/dashboard');
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("Acceptance of user conditions") }]);
  },
  methods: {
    ...mapActions("user", ["setAcceptedCondition"]),
    async acceptCondition() {
      if (this.checkGeneral && this.checkTerm) {
        await CompanyManagedCompaniesService.updateAcceptedCondition(this.getCurrentManagedCompany.id, true).then(() => {
          this.setAcceptedCondition(true);
          successToast();
          const path = this.$route.query.redirect;
          this.$router.push(path || '/dashboard');
        }).catch((err) => {
          backendErrorSwal(err, err?.response?.detail);
        })
      }
    },
    toggleGeneral(check) {
      this.generalDisabled = !check
    },
    toggleTerm(check) {
      this.termDisabled = !check
    }
  },
};
</script>

<!-- Load login custom page styles -->
<style lang="scss" scoped>
.show-label-terms,
.show-content-terms {
  max-width: 800px;
}

.show-content-terms {
  max-height: 250px;
  margin: 0 auto;
  border: 1px solid silver;
  border-radius: 5px;
  overflow-y: auto;
  text-align: left;
  opacity: .8;
}

.high-z-index {
  z-index: 9999 !important;
}
</style>
